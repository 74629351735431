<template>
  <div class="case-study-hero-banner">
    <UiImg
      :image="backgroundImage"
      :wrapper-index="index"
      class="case-study-hero-banner__hero-image"
      decorative
      :sizes="{
        xs: '400',
        sm: '768',
        md: '1024',
        lg: '1360',
        xl: '1600',
      }"
    />

    <div
      v-if="!backgroundImage"
      class="case-study-hero-banner__hero-image"
    />

    <div class="case-study-hero-banner__wrapper content-container-template">
      <T3HtmlParser
        v-if="content.header"
        tag="h1"
        class="case-study-hero-banner__title"
        :content="content.header"
      />

      <T3HtmlParser
        v-if="content.bodytext"
        :content="content.bodytext"
        class="case-study-hero-banner__text"
      />

      <ul
        v-if="content.categories.length"
        class="case-study-hero-banner__categories"
      >
        <li
          v-for="{ category } in content.categories"
          :key="category.name"
          class="case-study-hero-banner__category"
        >
          <T3HtmlParser :content="category.name" />
        </li>
      </ul>

      <UiImg
        :image="showcaseImage"
        :wrapper-index="index"
        class="case-study-hero-banner__showcase-image"
        :sizes="{ xs: '400', sm: '872' }"
        use-image-size
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { defineArticle, useSchemaOrg } from '#imports'

import { UiImg } from '~ui/components'
import type { UiCeCategoryBoxesProps } from '~ui/types'

const props = defineProps<UiCeCategoryBoxesProps>()

const content = ref(props.caseStudyList[0])

const backgroundImage = computed(() => content.value.image?.[0])
const showcaseImage = computed(() => content.value.logo?.[0])

const formatToIsoDate = (datetime?: number) => {
  return (datetime ? new Date(datetime * 1000) : new Date()).toISOString()
}

useSchemaOrg([
  defineArticle({
    '@type': 'Article',
    headline: content.value.header || '',
    keywords: props.pageTagList,
    image: showcaseImage.value?.publicUrl,
    datePublished: formatToIsoDate(props.pageData?.system?.crdate),
    dateModified: formatToIsoDate(props.pageData?.system?.tstamp)
  })
])
</script>

<style lang="scss">
.case-study-hero-banner {
  position: relative;
  color: color(white);
  text-align: center;
  padding: rem(64px);
  padding-bottom: 0;

  &__hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: rem(550px);
    z-index: z-index(below);
    background-color: rgba(color(black), 30%);

    @include media-query(max sm) {
      max-height: rem(750px);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);
    margin-inline: auto;

    @include media-query(sm) {
      gap: rem(24px);
    }
  }

  &__title {
    padding: 0;
    margin-bottom: 0;
    word-break: keep-all;
  }

  &__text p {
    font-size: rem(18px);
    margin: 0;
  }

  &__categories {
    margin: rem(20px) 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: rem(16px);
    justify-content: center;
    flex-wrap: wrap;
  }

  &__category {
    padding: em(4px) em(8px);
    border: 2px solid hsl(0 0% 100% / 70%);
    border-radius: em(2px);
    font-size: rem(12px);
    font-weight: 500;
    white-space: nowrap;
    line-height: 1.7;
  }

  &__showcase-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: rem(48px);
    box-shadow:
      0 2px 20px 0 hsl(0 0% 0% / 4%),
      0 8px 16px hsl(0 0% 0% / 5%),
      inset 872px 872px color(white);
    border-radius: em(4px);
    border: 10px solid color(white);
  }
}
</style>
